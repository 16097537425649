$primarycolor: #437A4A;
$secondarycolor: #e36f22;

@mixin border-radius-3 {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

body {
    -webkit-transition: padding-top 0.2s; /* Safari 3.1 to 6.0 */
    transition: padding-top 0.2s;
}
.font-white{ color: white; }

/**
    TABLES
 */
table.table {
    tr{
        &.even {
            td {
                background: #f1f1f1;
            }
        }
        &.odd {

        }
    }
}

/**
    QUICK SEARCH
 */

#nav-searchfield-results {
    background: rgba(255,255,255,0.95);
    width: 100%;
    min-height: 80px;
    position: absolute;
    text-align: left;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -moz-border-radius-bottomright: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .loading {
        margin-top: 30px;
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            color: #333;
            text-align: left;
            display: block;
            padding: 0;

            a {
                color: #333;
                padding: 12px 25px;
                display: block;
                text-transform: none;

                &:hover {
                    background: rgba(0,0,0,0.15);
                }
            }

            &.section {
                color: $primarycolor;
                font-weight: bold;
                border-bottom: 1px solid rgba($primarycolor, 0.5);
                margin: 10px 0 0 0;
                padding: 0 25px;
            }

        }
    }
}

header {

    -webkit-transition: top 0.2s; /* Safari 3.1 to 6.0 */
    transition: top 0.2s;

    #search_bar_container {
        display: inline-block;
        width: 330px;

        .search_bar {
            width: auto;

            .nav-searchfield-outer {
                background: transparent;
                color: #FFF;
                border: 1px solid #FFF;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;

                #twotabsearchtextbox{
                    background: transparent;
                    color: #FFF;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: #FFF;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: #FFF;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: #FFF;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: #FFF;
                    }
                }
            }

            .nav-submit-button {
                background-color: transparent;
            }
        }
    }
}

header.sticky {

    #logo {
        .logo_sticky {
            display: inline-block;
        }
    }
    .cobrand-title {
        color: #444;
        top: -18px;
        white-space: nowrap;
    }
    .no-sticky {
        display: none;
    }

}
header#plain, header.sticky {
    @extend header;

    #search_bar_container {
        .search_bar {
            .nav-searchfield-outer {
                color: #444 !important;
                border: 1px solid #666 !important;

                #twotabsearchtextbox{
                    color: #444;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: #444;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: #444;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: #444;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: #444;
                    }
                }
            }
        }
    }
}

.logo-home.logo-cobrand {

    .cobrand-title {
        position: absolute;
        font-size: 10px;
        top: -30px;
    }
    .logo-reseller {
        position: relative;
        margin-left: 20px;
    }
}

@media (max-width: 480px) {

    #hero {
        max-height: 185px;
    }
    ul#top_tools {
        margin-top: 50px;
    }
    header.sticky {
        ul#top_tools {
            margin-top: 0px;
            #search_bar_container {
                display: none;
            }
            #reservedAreaLink {
                display: none;
            }
            #shoppingCartLink {
                display: block;
            }
        }
    }

    nav {
        .main-menu {
            #reservedAreaLink, #shoppingCartLink, #helpFaqLink {
                i {
                    float: left;
                }
            }
        }
    }

    .logo-home.logo-cobrand {
    .logo-reseller {
      margin: 0;
    }
    .cobrand-title {
      font-size: inherit;
      position: static;
    }
  }
}

@media (min-width: 768px) {

  .logo-home.logo-cobrand {
    .cobrand-title {
      position: static;
      font-size: inherit;
    }
  }

}


@media (min-width: 992px) {

    .logo-home.logo-cobrand {
        .logo-default,
        .logo-reseller {
            display: inline-block;
            max-width: 45%;
            img {
                max-width: 100%;
            }
            .cobrand-title {
                position: absolute;
                font-size: 10px;
                top: -18px;
            }
        }
    }

}


/**
    MODAL
 */

.modal-content {

    @include border-radius-3;

    &.dark {
        border: 1px solid #666;
        background: rgba(0,0,0,0.7);

        .modal-title {
            color: white;
        }
        .modal-body *,
        .modal-header *{
            color: white;
        }
        .close{
            opacity: .7;
            position: absolute;
            top: 20px;
            right: 15px;

            &:focus {
                outline: none;
            }
        }
        .modal-footer {
            .btn-default {
                color: white;
                background-color: transparent;
                border-color: white;
            }
        }
    }
}

.chooseLanguageSuggestion {
    color: white !important;
}

.language-link {

    @include border-radius-3;
    padding: 10px;

    &:hover {
        background: rgba(255, 255, 255, 0.4);
    }
}

#voucherAlert {
    margin: 0;
    position: fixed;
    width: 100%;
    min-height: 60px;
    top: 0;
    left: 0;
    z-index: 200;

    .lead {
        margin: 0;
    }
    .btn {
        margin-top: -8px;
    }
}


/**
    ELEMENTS
 */

.box_style_5 {
    background: #fff;

    @include border-radius-3;

    border: 1px solid #ddd;
    margin-bottom: 25px;
    position: relative;
    color: #666;
    padding:10px 20px;

    .box_style_title {
        background: #f1f1f1;
        color: #333;
        padding:5px 30px;
    }

    .box_style_body {
        padding: 15px 30px;
    }
}
.tour_list_desc{
    .rating {
        small {
            color: #666;
        }
    }
}

/**
    AREA RISERVATA
 */
.order {
    padding: 0;

    .progress {
        margin: 7px 0 0 0;
        height: 26px;

        .progress-bar {
            line-height: 26px;
            font-size: 14px;
            font-weight:bold;
        }
    }

    .thumbnail{
        padding: 2px;
        margin: 0 15px 0 0;
    }

    .box_style_body {
        padding: 15px 20px;

        h4 {
            margin-top: 0;
        }
    }

    .order_item {

        .vouchers {
            i {
                font-size: 48px;
            }
        }

    }
}

/**
    PRODOTTI
 */
.tour_container {

    .tour_title {

        min-height: 92px;

        .rating {

            line-height: 16px;

            small {
                margin-left: 3px;
                color: #666;
            }

        }

    }

}
.writeReview {
    .rating {
        i {
            cursor: pointer;
        }
    }
}
.review_strip_single {
    small {
        img {
            border: none;
            position: static;
            margin-top: -2px;
        }
    }
    .rating {
        margin-left: -3px;

        .icon-star  {
            color: #999;
        }

        .voted {
            color: #F90;
        }
    }
}

#general_rating {
    .rating {

        .icon-star  {
            color: #999;
        }

        .voted {
            color: #F90;
        }
    }
}

.strip_all_tour_list.hover-animation {

    -webkit-transition: -webkit-transform .1s;
    -moz-transition: -moz-transform .1s;
    -o-transition: -o-transform .1s;
    transition: transform .1s;

    &:hover {

        -webkit-transform: translateY(-3px);
        -moz-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        -o-transform: translateY(-3px);
        transform: translateY(-3px);

        -webkit-box-shadow: 0 15px 20px -9px rgba(0, 0, 0, .25);
        -moz-box-shadow: 0 15px 20px -9px rgba(0, 0, 0, .25);
        box-shadow: 0 15px 20px -9px rgba(0, 0, 0, .25);

    }
}

#price_single_main {
    .normal_price_list {
        text-decoration: line-through;
        color: #FFF;
        font-size: 20px;
        margin-bottom: 10px;
        padding-left: 10px;
    }
}

.final-tiles-gallery.caption-top {
    .tile {
        span.normal_price_list{
            position:static;
            padding:0;
            margin:0;
            font-size: 16px;
        }
    }
}

.Prodotto .blockName_html {
    div.borderedFrame {
        border: 1px solid #ddd;
        margin-bottom: 20px;
        line-height: 0;
    }
    margin: 20px auto;
}

/**
    BOOKING BOX
 */
#booking_box {
    .variant-field {
        text-align: left !important;
    }
    .form-control {
        font-size: 14px;
        text-align: center;
    }
    .openedCal {
        min-height: 200px;
    }
    .spinner {
        margin: 10px auto;
    }
    #sold_out {
        position: absolute;
        z-index: 1100;
        top: 55px;
        left: 0;
        padding: 30px;
        background: rgba(255, 255, 255, 0.8);
    }
    label.full-width {
        width: 100%;
    }
    .hasTooltip:hover {
        cursor: help;
    }

}

input.qty2 {
    &.subproductVariantQty,
    &.productVariantQty {
        width: 50px !important;
    }
}

.openedCal {
    .pika-single {
        z-index: 99;
    }
}

.pika-lendar {

    width: 280px;
    font-family:"Lato", Arial, sans-serif;

    .pika-label {
        font-size: 20px;
        font-weight: normal;

    }
    .pika-prev,
    .pika-next {
        border: 2px solid #333;
        padding: 3px 13px;

        &.is-disabled {
            visibility: hidden;
        }
    }
    .pika-table {
        th {
            font-size: 14px;

            abbr:hover {
                cursor: default;
            }
        }
        td {
            border: 1px solid #c5e8a5;

            &.is-disabled {
                border-color: #d9dad0;
                .pika-button {
                    background: #e3e4df;
                    color: #333;
                }
                &.is-outside-current-month {
                    .pika-button {
                        color: #fff;
                    }
                }
            }
            &.is-empty {
                border-color: #d9dad0;
            }
            &.is-today {
                .pika-button {
                    text-decoration: underline;
                }
            }
        }
    }
    .pika-button {
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        font-weight: bold;
        background: #7ec440;
        color: #FFF;
        border-radius: 0;
    }

    .is-selected .pika-button,
    .pika-button:hover {
        background: #33aaff;
        color: #FFF;
        border-radius: 0;
    }

}

.tooltip.error {

    font-size: 15px;

    .tooltip-inner {

        background: #c60000;

    }

    .tooltip-arrow {
        border-top-color: #c60000;
    }

}

/**
    CARRELLO
 */
.cart-row {
    margin-bottom: 10px;
    position: relative;

    .cart-row-remove{
        position:absolute;
        top: 5px;
        right: 5px;
        color: #666;
        z-index: 20;
    }
    .img_list,
    .tour_list_desc,
    .price_list {
        min-height: 140px;
        height: 0;
    }

    .tour_list_desc {
        h3 {
            margin-bottom: 5px;
        }
    }

    .price_list {
        font-size: 26px;
        small{
            margin:0;
        }
    }
    .normal_price_list {
        font-size: 16px;
        display: block;
    }
    .cart_product_info {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            margin-bottom: 5px;

            &.inline {
                display:inline-block;
                margin-right: 15px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .cart-item-loading {
        background: rgba(255,255,255,0.7);
        display:block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;

        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
        }
    }

    &.productExpired {

        position: relative;

        .cart-row-overlay {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background: rgba(255, 255, 255, 0.85);

        }
    }
}

.cart-related-product {

    .item-price {

        text-align: center;
        font-size: 38px;
        color: #e74c3c;

        small {
            font-size: 11px;
            color: #999;
            display: block;
            margin-bottom: 10px;
            line-height: 12px;
        }

        sup {
            font-size: 22px;
        }

        .normal_price_list {
            text-decoration: line-through;
            color: #999;
            font-size: 20px;
            margin-bottom: 5px;
        }
    }

}

.cart-checkout-box {
    padding:10px 20px;
}

.cart-coupon {
    input.form-control {
        height: 40px;
    }
    .btn_map {
        padding: 8px 15px;
    }
    input.btn_map {
        height: 38px;
    }
}

.payment-step {

    .token-payment{

        border: 2px solid transparent;

        &:hover,
        &:focus{
            border: 2px solid $secondarycolor;
            cursor: pointer;
        }

    }

    .form-group {
        margin:0;

        &.relief {
            background-color: #fff;
            display: block;
            min-height: 50px;
            -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            overflow: hidden;
        }

        label.portlet {
            display: block;
            color: #777;
            line-height: 50px;
        }

        .Cart_ShoppingCart_Extension_Template {
            margin: -20px 0 20px 30px;
        }
    }
}

.payment-standalone {
    .step {
        border: none;
        padding: 0;
        margin: 20px 0 0 0;
    }
}

.btn_1.loadingClick {
    .spinner {
        display: inline-block;
        position: relative;
        top: 3px;
        right: 5px;
    }
}
.normal_price_list{
    text-decoration: line-through;
}

form {

    button[disabled] {
        background: #777;

        .spinner {
            display: inline-block;
            position: relative;
            top: 3px;
            right: 10px;
        }
    }

    .btn_1.medium.loadingClick {
        .spinner {
            display: inline-block;
            position: relative;
            top: 3px;
            right: 5px;
        }
    }
}


/**
    LOADING SPINNER CIRCLES
 */

.circle {
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba($primarycolor, 0.9);
    opacity: .9;
    border-right: 5px solid rgba(0,0,0,0);
    border-left: 5px solid rgba(0,0,0,0);
    border-radius: 50px;
    /*box-shadow: 0 0 35px #2187e7;*/
    width: 50px;
    height: 50px;
    margin: 0 auto;
    -moz-animation: spinPulse 1s infinite ease-in-out;
    -webkit-animation: spinPulse 1s infinite linear;
}

.circle1 {
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba($secondarycolor, 0.9);
    opacity: .9;
    border-left: 5px solid rgba(0,0,0,0);
    border-right: 5px solid rgba(0,0,0,0);
    border-radius: 50px;
    /*box-shadow: 0 0 15px #2187e7;*/
    width: 30px;
    height: 30px;
    margin: 0 auto;
    position: relative;
    top: -40px;
    -moz-animation: spinoffPulse 1s infinite linear;
    -webkit-animation: spinoffPulse 1s infinite linear;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@include keyframes(spinPulse) {
    0% {
        transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px $primarycolor;
    }
    50% {
        transform: rotate(145deg);
        opacity: 1;
    }
    100% {
        transform: rotate(-320deg);
        opacity: 0;
    }
}

@include keyframes(spinoffPulse) {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*
    LOADING CIRCLE
 */

$base-line-height: 24px;
$base-line-height-small: 18px;
$base-line-height-large: 38px;
$white: rgb(255,255,255);
$dark: rgb(0,0,0);
$off-white: rgba($white, 0.2);
$off-dark: rgba($dark, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .25rem solid $off-white;
    border-top-color: $white;
    animation: spin $spin-duration infinite linear;
    &.spinner-dark {
        border: .25rem solid $off-dark;
        border-top-color: $dark;
    }
    &.spinner-small {
        width: $base-line-height-small;
        height: $base-line-height-small;
        border-width: .15rem;
    }
    &.spinner-large {
        width: $base-line-height-large;
        height: $base-line-height-large;
        border-width: .45rem;
    }

}

/**
    COOKIE LAW
 */

#cookie_alert {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: -10px;
    z-index: 10;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    a.btn_1, .btn_1 {
        padding: 8px 10px;
        font-size:11px;
        line-height:10px;
    }
}
